<template>
  <v-container fluid>
    <v-card v-for="(faq, faq_id) in faqs" :key="faq_id" class="mb-4">
      <v-card-title class="heading-text" v-html="faq.question" />
      <v-card-subtitle class="subtitle-text" v-html="faq.answer" />
    </v-card>
  </v-container>
</template>


<script>
export default {
  data() {
    return {
      faqs: [
        {
          question: this.$t("what_is_data_collected"),
          answer: this.$t("what_is_data_collected_answer"),
        },
        {
          question: this.$t("purpose_of_data"),
          answer: this.$t("purpose_of_data_answer"),
        },
        {
          question: this.$t("what_are_rewards"),
          answer: this.$t("what_are_rewards_answer"),
        },
        {
          question: this.$t("can_see_own_data"),
          answer: this.$t("can_see_own_data_answer"),
        },
        {
          question: this.$t("privacy_taken_care"),
          answer: this.$t("privacy_taken_care_answer"),
        },
        {
          question: this.$t("data_protection_taken_care"),
          answer: this.$t("data_protection_taken_care_answer"),
        },
        {
          question: this.$t("who_to_contact"),
          answer: this.$t("who_to_contact_answer"),
        },
      ],
    };
  },
};
</script>

<style scoped>
.heading-text {
  word-break: keep-all;
  color: black;
  font-size: 18px !important;
}
.subtitle-text {
  color: black !important;
  font-size: 16px !important;
}
</style>